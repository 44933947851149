import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'projectStageInfo',
  standalone: true
})
export class ProjectStageInfoPipe implements PipeTransform {
  public transform(stage: string): string {
    switch (stage) {
      case 'Formation - Exploratory':
        return 'Initial stage to capture early discussions';
      case 'Formation - Engaged':
        return 'Enters this stage when formation discussions begin';
      case 'Active':
        return 'Project is publicly launched';
      case 'Archived':
        return 'Project is no longer active';
      case 'Formation - On Hold':
        return 'Project in Formation - On Hold, but has decided to put the discussions to move forward on hold';
      case 'Formation - Disengaged':
        return 'Project in Formation - Disengaged, but has cancelled plans to move or not responsive';
      case 'Formation - Confidential':
        return 'Project in Formation - Confidential, but employees are not allowed to talk about it';
      case 'Prospect':
        return (
          'Stage for potential projects coming in from as external source, like Community Bridge.' +
          ' LF employees on the project formation team should not choose this value'
        );
      default:
        return '';
    }
  }
}
