import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { ProjectService } from '@app/shared/services/project.service';
import { UserService } from '@app/shared/services/user.service';
import { INDUSTRY_SECTORS, OPEN_SOURCE_LICENSES, STATUS_TYPES, TECHNICAL_ACTIVITY_TYPE, TECHNOLOGY_SECTORS } from '@app/shared/utils/constants';
import { flattenSummaryData } from '@app/shared/utils/helper';
import { fieldRequired, getError, hasError, required, website } from '@app/shared/utils/validators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Artifact, Project, ProjectCreate, ProjectSummary, ProjectUpdate } from 'lfx-pcc';
import { sortBy } from 'lodash';
import { DateTime } from 'luxon';
import mime from 'mime';
import { MessageService } from 'primeng/api';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxChangeEvent, CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { catchError, debounceTime, forkJoin, map, Observable, of, switchMap, take, tap } from 'rxjs';

import { FileUploadInputComponent } from '../file-upload-input/file-upload-input.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'lfx-create-project',
  standalone: true,
  imports: [
    CommonModule,
    TooltipModule,
    InputTextModule,
    AutoCompleteModule,
    CheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    DropdownModule,
    ButtonModule,
    MultiSelectModule,
    CalendarModule,
    InputTextareaModule,
    FileUploadInputComponent,
    SkeletonModule,
    MessagesModule
  ],
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss']
})
export class CreateProjectComponent implements OnInit {
  public form: FormGroup;
  public formLoading: boolean = false;
  public parentProjectOptions: ProjectSummary[] = [];
  public disableParentProject: boolean = true;
  public industrySectors = sortBy(INDUSTRY_SECTORS, 'label');
  public technicalActivityTypes = sortBy(TECHNICAL_ACTIVITY_TYPE, 'label');
  public technologySectors = sortBy(TECHNOLOGY_SECTORS, 'label');
  public statusTypes = sortBy(STATUS_TYPES, 'label').filter((status) => !['Active', 'Archived', 'Formation - Engaged'].includes(status.label));
  public licenseTypes = sortBy(OPEN_SOURCE_LICENSES, 'label');
  public categories$: Observable<string[]>;
  public step: number = 1;
  public customSlug: boolean = false;

  public commonNameTooltip: string = `This is the official name of the project, but not necessarily its legal name.
  If unsure, pick a close or descriptive name.`;
  public slugTooltip: string = `The "slug" (URL fragment) is a unique project identifier suitable for using in a URL,
    and is used to reference projects in a recognizable way by services within and outside the LFX platform. It is
    typically NOT changed once the project has been added.`;
  public formationDateTooltip: string = `Formation date is not the same as the project start date, but is when the project
    became officially associated with the Linux Foundation or respective entity. If unsure, leave blank.`;
  public categoryTooltip: string = `Optionally organize projects within a project group into categories for grouping
    and navigation. This is distinct from setting a technology or industry sector.`;
  public noParentTooltip: string = `"No Parent" should only be used for incorporated projects; otherwise select
    "The Linux Foundation" as the parent, if not part of an existing project group.`;
  public statusTooltip: string = `Some status options are only available once a project has been created, in the Project Definition page.`;
  public technicalActivityTooltip: string = `If 'Open Source Software' is selected, please pick a Primary Open Source License.`;
  public missionStatementTooltip: string =
    'The mission statement should be a single sentence statement that begins with the words ' +
    '"The mission of the project is to..." and is followed by the primary purpose of the project or primary goal of the project.';
  public assignmentAgreementNeededTooltip: string = `The link to the project's Assignment Agreement document, \
  if such an agreement is required for this project`;
  public assignmentAgreementURLTooltip: string = `Indicates whether an Assignment Agreement is required, not required, \
  or yet to be determined for the project, with 'Not determined' set as the default status for all new projects`;

  private fb: FormBuilder = inject(FormBuilder);
  private projectService: ProjectService = inject(ProjectService);
  private userService: UserService = inject(UserService);
  private dialogRef: DynamicDialogRef = inject(DynamicDialogRef);
  private dialogConfig: DynamicDialogConfig<{ parent: Project }> = inject(DynamicDialogConfig);
  private messageService: MessageService = inject(MessageService);

  public constructor() {
    this.form = this.fb.group({
      Parent: [{ value: '', disabled: this.disableParentProject }],
      Name: ['', required, [this.nameValidator().bind(this)]],
      Slug: ['', [required], [this.slugValidator().bind(this)]],
      Status: ['Prospect'],
      Website: ['', website],
      Category: [''],
      Technology: [{ value: [], disabled: this.isCross('Technology') }],
      Industry: [{ value: [], disabled: this.isCross('Industry') }],
      TechActivityType: [[], this.techActivityTypeValidator.bind(this)],
      Description: [''],
      StartDate: [''],
      ExpectedAnnouncementDate: [''],
      ProjectLogoFile: [''],
      ProjectLogoURL: [''],
      EntityType: [''],
      RepositoryURL: ['', website],
      AssignmentAgreementNeeded: [false],
      AssignmentAgreementNeededVal: ['Not Defined'],
      AssignmentAgreementURL: [''],
      AssignmentAgreementFile: [''],
      PrimaryOpenSourceLicense: [null],
      MissionStatement: ['', required]
    });

    this.initFormChanges();
  }

  public ngOnInit(): void {
    this.categories$ = this.projectService.getProjectCategories().pipe(
      map((categories) => categories.Data || []),
      // Remove NONE category
      map((categories) => categories.filter((category) => category !== 'NONE')),
      // remove empty values
      map((categories) => categories.filter((c) => !!c)),
      // remove duplicates
      map((categories) => categories.filter((c, i) => categories.indexOf(c) === i)),
      // sort alphabetically
      map((categories) => categories.sort((a, b) => a.localeCompare(b)))
    );

    if (this.dialogConfig.data?.parent) {
      this.disableParentProject = false;
      this.form.controls.Parent.enable();
      this.form.controls.Parent.setValue(this.dialogConfig.data.parent, { emitEvent: false });
    }
  }

  public get isNextDisabled(): boolean {
    const fields = ['Name', 'Slug', 'Parent', 'Website', 'Category', 'Status'];

    return fields.some((field) => this.form.controls[field].invalid);
  }

  public get isFormationTeam(): boolean {
    return this.userService.legalUser;
  }

  public get isFirstStep(): boolean {
    return this.step === 1;
  }

  public hasError(controlName: string): boolean {
    return hasError(controlName, this.form);
  }

  public getError(controlName: string): string {
    return getError(controlName, this.form);
  }

  public fieldRequired(controlName: string): boolean {
    return fieldRequired(controlName, this.form);
  }

  public handleParentSearch(event: AutoCompleteCompleteEvent): void {
    const params = new HttpParams({
      fromObject: {
        $filter: `name contains ${event.query}`,
        orderBy: 'name',
        sortDir: 'asc',
        noCache: 'true',
        parentHierarchy: 'true',
        pageSize: '10',
        view: 'pcc'
      }
    });

    this.projectService
      .getProjectSummary(params)
      .pipe(
        take(1),
        map((projects) => flattenSummaryData(projects, true))
      )
      .subscribe((projects) => {
        this.parentProjectOptions = projects;
      });
  }

  public onCheckNoParent(event: CheckboxChangeEvent): void {
    if (event.checked) {
      this.form.controls.Parent.setValue('');
      this.form.controls.Parent.disable();
    } else {
      this.form.controls.Parent.enable();
    }
  }

  public onCrossChange(event: CheckboxChangeEvent, type: 'Technology' | 'Industry'): void {
    if (event.checked) {
      this.form.controls[type].patchValue([`Cross-${type}`]);
      this.form.controls[type].disable();
    } else {
      this.form.controls[type].enable({ emitEvent: false });
      this.form.controls[type].patchValue([]);
    }

    this.form.controls[type].updateValueAndValidity();
  }

  public isCross(type: 'Technology' | 'Industry'): boolean {
    return this.form?.controls[type]?.value.includes(`Cross-${type}`);
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onNext(): void {
    this.step++;
  }

  public onPrevious(): void {
    this.step--;
  }

  public onReset(): void {
    this.customSlug = false;
    this.setSlug(this.form.controls.Name.value);
  }

  public onCreate(): void {
    if (this.form.valid) {
      this.formLoading = true;
      const data: ProjectCreate = {
        ...this.form.value,
        Category: this.form.value.Category ? this.form.value.Category : 'NONE',
        StartDate: this.form.value.StartDate ? DateTime.fromJSDate(this.form.value.StartDate).toISODate() : null,
        ExpectedAnnouncementDate: this.form.value.ExpectedAnnouncementDate ? DateTime.fromJSDate(this.form.value.ExpectedAnnouncementDate).toISODate() : null,
        Industry: this.form.controls.Industry.getRawValue() || [],
        AssignmentAgreementNeeded: this.form.controls.AssignmentAgreementNeededVal.value,

        Technology: this.form.controls.Technology.getRawValue() || [],
        ProjectType: 'Project',
        Status: this.form.value.Status || 'Prospect',
        Parent: this.form.value.Parent ? this.form.value.Parent.ID : null,
        EntityType: 'None'
      };

      this.projectService
        .postProject(data)
        .pipe(
          catchError((error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: `${error.error?.message?.Message || 'Failed to create project'}. Please try again or contact support.`,
              data: error.error,
              key: 'support'
            });

            return of(null);
          }),
          take(1)
        )
        .subscribe((project) => {
          if (project) {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: `Project ${project.Name} created`
            });
            this.uploadDocuments(project);
          } else {
            this.formLoading = false;
          }
        });
    }
  }

  private nameValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      // const params = new HttpParams({ fromObject: { $filter: `name eq ${control.value.trim()}`, view: 'pcc' } });
      const params = new HttpParams({ fromObject: { name: control.value.trim() } });
      return this.projectService.getSearchProjects(params).pipe(
        debounceTime(500),
        catchError(() => {
          return of({
            Data: []
          });
        }),
        take(1),
        map((project) => {
          if (project.Data.some((p) => p.Name.toLowerCase() === control.value.toLowerCase())) {
            return { Name: 'A project with that name already exists' };
          }

          return null;
        })
      );
    };
  }

  private slugValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const regex = /^[a-z][a-z0-9-_]*[a-z0-9]$/;

      if (!regex.test(control.value)) {
        return of({ Slug: 'Use numbers, lowercase letters, dashes, and underscores. Must start with a letter and end with a number or letter.' });
      }

      const params = new HttpParams({ fromObject: { $filter: `slug eq ${control.value.trim()}` } });
      return this.projectService.getSearchProjects(params).pipe(
        debounceTime(500),
        take(1),
        map((project) => {
          if (project && project.Data.length) {
            return { Slug: 'A project with that slug already exists' };
          }

          return null;
        })
      );
    };
  }

  private setSlug(value: string): void {
    // Create a slug from the name (letters and numbers only) using only lowercase letters, numbers, and dashes
    const slug = value
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');

    if (this.form.controls.Slug.value === '' || !this.customSlug) {
      this.form.controls.Slug.patchValue(slug, { emitEvent: false });
      this.form.controls.Slug.markAsDirty();
    }
  }

  private techActivityTypeValidator(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;
    const primaryOpenSourceLicense = control.parent?.get('PrimaryOpenSourceLicense')?.value;

    if (value.includes('Open Source Software') && !primaryOpenSourceLicense) {
      return { TechActivityType: 'Primary open source license is required for "open source software" projects' };
    }

    return null;
  }

  private uploadFiles(project: Project) {
    const files = [];
    if (this.form.controls.ProjectLogoFile.value) {
      files.push('ProjectLogo');
    }
    if (this.form.controls.AssignmentAgreementFile.value) {
      files.push('AssignmentAgreement');
    }
    if (!files || files.length === 0) {
      return null;
    }

    const queries = files.map((field) => this.uploadFile(field, project)) as Observable<any>[];
    return forkJoin([...queries]);
  }

  private uploadFile(field: string, project: Project) {
    const file = this.form.value[`${field}File`] as File;
    if (!file) {
      return;
    }
    const filename = `${project.Slug || project.Name}_${field}.${mime.getExtension(file.type)}`;
    const uploadParams = new HttpParams({
      fromObject: { filename, overwrite: 'true', type: 'public' }
    });

    return this.projectService.putUploadArtifact(project.ID, file, uploadParams).pipe(
      catchError((error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to upload logo/document. Please try again or contact support.',
          data: error,
          key: 'support'
        });
        return of(error);
      }),
      take(1),
      switchMap((res) => {
        if (res.status === 200 || res.status === 201) {
          return this.saveArtifact(
            {
              Name: filename,
              Type: mime.getExtension(file.type) !== 'svg' ? 'Document' : 'Logo',
              URL: res.body?.FileURL as string
            },
            field,
            project
          );
        }

        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to upload logo/document. Please try again or contact support.',
          data: { error: res },
          key: 'support'
        });

        return of(res);
      })
    );
  }

  private saveArtifact(artifactData: Artifact, field: string, project: Project) {
    return this.projectService.postProjectArtifacts(project.ID, artifactData).pipe(
      catchError((error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to save document. Please try again or contact support.',
          data: error,
          key: 'support'
        });

        this.formLoading = false;
        return of(error);
      }),
      take(1),
      tap(() => {
        this.form.controls[`${field}URL`].setValue(`${artifactData.URL}?v=${Date.now()}`, { emitEvent: false });
      })
    );
  }

  private uploadDocuments(project: Project) {
    const uploads = this.uploadFiles(project);
    if (uploads) {
      uploads
        .pipe(
          catchError((error) => {
            console.error(error);
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Error uploading documents. Please try again or contact support',
              data: error,
              key: 'support'
            });

            return of(null);
          }),
          take(1)
        )
        .subscribe((res) => {
          if (res) {
            // A per the Jorden's comment on this
            // Box not checked and upload -> yes
            // box checked -> grey out upload -> no
            // no box checked and no upload -> “Not Defined
            if (this.form.controls.AssignmentAgreementNeeded.value) {
              this.form.controls.AssignmentAgreementNeededVal.setValue('No');
            } else {
              if (this.form.controls.AssignmentAgreementURL.value) {
                this.form.controls.AssignmentAgreementNeededVal.setValue('Yes');
              } else {
                this.form.controls.AssignmentAgreementNeededVal.setValue('Not Defined');
              }
            }
            const data: ProjectUpdate = {
              ProjectLogo: this.form.controls.ProjectLogoURL.value,
              AssignmentAgreementNeeded: this.form.controls.AssignmentAgreementNeededVal.value,
              AssignmentAgreementURL:
                !this.form.controls.AssignmentAgreementURL.value || this.form.controls.AssignmentAgreementURL.value.length === 0
                  ? ' '
                  : this.form.controls.AssignmentAgreementURL.value
            };
            this.projectService.patchUpdateProject(project.ID, data).subscribe((updateResponse) => {
              if (updateResponse) {
                this.dialogRef.close(project);
                this.formLoading = false;
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Failed to update uploaded files',
                  data: { error: updateResponse },
                  key: 'support'
                });
              }
            });
          } else {
            this.formLoading = false;
          }
        });
    } else {
      this.formLoading = false;
      this.dialogRef.close(project);
    }
  }

  private initFormChanges(): void {
    this.form.controls.Name.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe((value) => {
      this.setSlug(value);
    });

    this.form.controls.Slug.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe(() => {
      this.customSlug = true;
    });

    this.form.controls.Technology.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value.includes('Cross-Technology') && value.length > 1) {
        this.onCrossChange({ checked: true }, 'Technology');
      }
    });

    this.form.controls.Industry.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value.includes('Cross-Industry') && value.length > 1) {
        this.onCrossChange({ checked: true }, 'Industry');
      }
    });

    this.form.controls.TechActivityType.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value.includes('Open Source Software')) {
        this.form.controls.PrimaryOpenSourceLicense.addValidators([Validators.required]);
        this.form.controls.PrimaryOpenSourceLicense.updateValueAndValidity();
        this.form.controls.PrimaryOpenSourceLicense.markAsDirty();
      }
    });

    this.form.controls.PrimaryOpenSourceLicense.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.form.controls.TechActivityType.updateValueAndValidity();
    });

    this.form.controls.AssignmentAgreementNeeded.valueChanges.pipe(untilDestroyed(this)).subscribe((checked: boolean) => {
      if (checked) {
        this.form.controls.AssignmentAgreementFile.disable();
        this.form.controls.AssignmentAgreementFile.setValue('');
        this.form.controls.AssignmentAgreementURL.setValue('');
      } else {
        this.form.controls.AssignmentAgreementFile.enable();
      }
    });

    this.form.controls.Status.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value === 'Formation - Engaged') {
        this.form.controls.ExpectedAnnouncementDate.setValidators([Validators.required]);
        this.form.controls.Industry.setValidators([Validators.required, Validators.minLength(1)]);
        this.form.controls.Technology.setValidators([Validators.required, Validators.minLength(1)]);
        this.form.controls.TechActivityType.setValidators([Validators.required, Validators.minLength(1), this.techActivityTypeValidator.bind(this)]);
        this.form.controls.Description.setValidators([required]);
      } else {
        this.form.controls.ExpectedAnnouncementDate.setValidators([]);
        this.form.controls.Industry.setValidators([]);
        this.form.controls.Technology.setValidators([]);
        this.form.controls.TechActivityType.setValidators([this.techActivityTypeValidator.bind(this)]);
        this.form.controls.Description.setValidators([]);
      }

      const fields = ['ExpectedAnnouncementDate', 'Industry', 'Technology', 'TechActivityType', 'Description', 'PrimaryOpenSourceLicense'];
      fields.forEach((field) => {
        this.form.controls[field].updateValueAndValidity();
      });
    });
  }
}
