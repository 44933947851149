<div [formGroup]="form" class="flex flex-col gap-4">
  <div class="flex flex-col gap-4" *ngIf="isFirstStep">
    <div>
      <p-messages severity="info">
        <ng-template pTemplate>
          <div class="w-full flex justify-between items-center">
            <div class="flex gap-4 items-center">
              <i class="fa-solid fa-triangle-exclamation text-22"></i>
              <p class="text-13">
                Please enter as much information about the project as you can. Note that you will be required to enter the "parent project" - the name of the
                umbrella if this project is part of an umbrella - for the new project. From the information you enter, a "draft" project will be created and a
                notice will be sent to the formation team. The formation team will then review this project and reach out to you with any questions or draft
                governance materials for your review. If at any point you have any questions, please reach out to
                <a href="mailto:formation@linuxfoundation.org" target="_blank">formation&#64;linuxfoundation.org</a>.
              </p>
            </div>
          </div>
        </ng-template>
      </p-messages>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-6 gap-x-9">
      <div class="project-detail">
        <label
          >Common Name <span class="text-red" *ngIf="fieldRequired('Name')">*</span>
          <i
            class="fa-regular fa-info-circle ml-2"
            appendTo="body"
            [pTooltip]="commonNameTooltip"
            tooltipPosition="top"
            [escape]="false"
            [autoHide]="false"></i>
        </label>
        <input type="text" pInputText formControlName="Name" />
        <small *ngIf="hasError('Name')" class="text-xs text-red">{{ getError('Name') }}</small>
      </div>
      <div class="project-detail">
        <label
          >Category
          <i class="fa-regular fa-info-circle ml-2" appendTo="body" [pTooltip]="categoryTooltip" tooltipPosition="top" [escape]="false" [autoHide]="false"></i>
        </label>
        <ng-container *ngIf="categories$ | async as categories; else inputLoading">
          <p-dropdown [options]="categories" formControlName="Category" appendTo="body" placeholder="Select category"></p-dropdown>
        </ng-container>
      </div>
      <div class="project-detail">
        <label
          >Slug <span class="text-red" *ngIf="fieldRequired('Slug')">*</span>
          <i class="fa-regular fa-info-circle ml-2" appendTo="body" [pTooltip]="slugTooltip" tooltipPosition="top" [escape]="false" [autoHide]="false"></i>
        </label>
        <div class="flex gap-2 items-center">
          <input type="text" pInputText formControlName="Slug" class="flex-grow" />
          <a class="text-xs cursor-pointer" (click)="onReset()" *ngIf="customSlug">Reset</a>
        </div>
        <small *ngIf="hasError('Slug')" class="text-xs text-red">{{ getError('Slug') }}</small>
      </div>
      <div class="project-detail">
        <div class="flex flex-col lg:flex-row justify-between gap-2">
          <label>Parent Project <span class="text-red" *ngIf="fieldRequired('Parent')">*</span></label>
          <div class="flex items-center gap-1">
            <label class="cursor-pointer">
              <p-checkbox
                (onChange)="onCheckNoParent($event)"
                [binary]="true"
                [(ngModel)]="disableParentProject"
                [ngModelOptions]="{ standalone: true }"></p-checkbox>
              No Parent</label
            >
            <i
              class="fa-regular fa-info-circle ml-1"
              appendTo="body"
              [pTooltip]="noParentTooltip"
              tooltipPosition="top"
              [escape]="false"
              [autoHide]="false"></i>
          </div>
        </div>
        <p-autoComplete
          formControlName="Parent"
          [suggestions]="parentProjectOptions"
          (completeMethod)="handleParentSearch($event)"
          appendTo="body"
          [delay]="500"
          dataKey="ID"
          field="Name"
          placeholder="Search for a project"
          panelStyleClass="legal-dialog-dropdown">
          <ng-template let-project pTemplate="item">
            <div class="flex align-items-center gap-2 level-{{ project.level }}">
              <div class="whitespace-nowrap overflow-ellipsis overflow-hidden">{{ project.Name }}</div>
            </div>
          </ng-template>
        </p-autoComplete>
        <small *ngIf="hasError('Parent')" class="text-xs text-red">{{ getError('Parent') }}</small>
      </div>
      <div class="project-detail" *ngIf="isFormationTeam">
        <label
          >Stage <span class="text-red" *ngIf="fieldRequired('Status')">*</span>
          <i class="fa-regular fa-info-circle ml-2" appendTo="body" [pTooltip]="statusTooltip" tooltipPosition="top" [escape]="false" [autoHide]="false"></i>
        </label>
        <p-dropdown [options]="statusTypes" formControlName="Status" appendTo="body"></p-dropdown>
        <small *ngIf="hasError('Status')" class="text-xs text-red">{{ getError('Status') }}</small>
      </div>
      <div class="project-detail">
        <label>Primary Website/Domain <span class="text-red" *ngIf="fieldRequired('Website')">*</span></label>
        <input type="text" pInputText formControlName="Website" />
        <small *ngIf="hasError('Website')" class="text-xs text-red">{{ getError('Website') }}</small>
      </div>
      <div class="project-detail">
        <label>Repository URL <span class="text-red" *ngIf="fieldRequired('RepositoryURL')">*</span></label>
        <input type="text" pInputText formControlName="RepositoryURL" />
        <small *ngIf="hasError('RepositoryURL')" class="text-xs text-red">{{ getError('RepositoryURL') }}</small>
      </div>
      <lfx-file-upload-input
        [form]="form"
        fileControlName="ProjectLogoFile"
        fileLabel="Project Logo"
        placeholder="Upload logo"
        [showToggle]="false"
        accept="image/svg+xml"
        [fileValidators]="[]"></lfx-file-upload-input>
    </div>
  </div>
  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-6 gap-x-9" *ngIf="!isFirstStep">
    <div class="project-detail">
      <div class="flex flex-col lg:flex-row justify-between gap-2">
        <label>Technology Sector <span class="text-red" *ngIf="fieldRequired('Technology')">*</span></label>
        <div class="flex items-center gap-1">
          <label class="cursor-pointer">
            <p-checkbox
              (onChange)="onCrossChange($event, 'Technology')"
              [ngModel]="isCross('Technology')"
              [ngModelOptions]="{ standalone: true }"
              [binary]="true"></p-checkbox>
            Cross-Technology</label
          >
        </div>
      </div>
      <p-multiSelect [options]="technologySectors" formControlName="Technology" appendTo="body" placeholder="Select Technology"></p-multiSelect>
      <small *ngIf="hasError('Technology')" class="text-xs text-red">{{ getError('Technology') }}</small>
    </div>
    <div class="project-detail">
      <div class="flex flex-col lg:flex-row justify-between gap-2">
        <label>Industry Sector <span class="text-red" *ngIf="fieldRequired('Industry')">*</span></label>
        <div class="flex items-center gap-1">
          <label class="cursor-pointer">
            <p-checkbox
              (onChange)="onCrossChange($event, 'Industry')"
              [ngModel]="isCross('Industry')"
              [ngModelOptions]="{ standalone: true }"
              [binary]="true"></p-checkbox>
            Cross-Industry</label
          >
        </div>
      </div>
      <p-multiSelect [options]="industrySectors" formControlName="Industry" appendTo="body" placeholder="Select Industry"></p-multiSelect>
      <small *ngIf="hasError('Industry')" class="text-xs text-red">{{ getError('Industry') }}</small>
    </div>
    <div class="project-detail">
      <div class="flex gap-2">
        <label
          >Technical Activity Type
          <span class="text-red" *ngIf="fieldRequired('TechActivityType')">*</span>
          <i class="fa-regular fa-info-circle ml-2" appendTo="body" [pTooltip]="technicalActivityTooltip" tooltipPosition="top"></i
        ></label>
      </div>
      <p-multiSelect
        [options]="technicalActivityTypes"
        formControlName="TechActivityType"
        appendTo="body"
        placeholder="Select Technical Activity Type"></p-multiSelect>
      <small *ngIf="hasError('TechActivityType')" class="text-xs text-red">{{ getError('TechActivityType') }}</small>
    </div>
    <div *ngIf="isFormationTeam" class="project-detail">
      <label
        >Formation Date
        <span class="text-red" *ngIf="fieldRequired('StartDate')">*</span>
        <i
          class="fa-regular fa-info-circle ml-2"
          appendTo="body"
          [pTooltip]="formationDateTooltip"
          tooltipPosition="top"
          [escape]="false"
          [autoHide]="false"></i>
      </label>
      <p-calendar placeholder="MM/DD/YYYY" appendTo="body" formControlName="StartDate"></p-calendar>
      <small *ngIf="hasError('StartDate')" class="text-xs text-red">{{ getError('StartDate') }}</small>
    </div>
    <div class="project-detail">
      <label>Expected Announcement Date <span class="text-red" *ngIf="fieldRequired('ExpectedAnnouncementDate')">*</span></label>
      <p-calendar placeholder="MM/DD/YYYY" appendTo="body" formControlName="ExpectedAnnouncementDate"></p-calendar>
      <small *ngIf="hasError('ExpectedAnnouncementDate')" class="text-xs text-red">{{ getError('ExpectedAnnouncementDate') }}</small>
    </div>
    <div class="project-detail">
      <label>Primary Open Source License <span class="text-red" *ngIf="fieldRequired('PrimaryOpenSourceLicense')">*</span></label>
      <p-dropdown [options]="licenseTypes" formControlName="PrimaryOpenSourceLicense" appendTo="body" placeholder="Select Open Source License"></p-dropdown>
      <small *ngIf="hasError('PrimaryOpenSourceLicense')" class="text-xs text-red">{{ getError('PrimaryOpenSourceLicense') }}</small>
    </div>

    <div class="project-detail">
      <div class="flex flex-row flex-wrap justify-between gap-2">
        <label
          >Assignment Agreement URL <span class="text-red" *ngIf="fieldRequired('AssignmentAgreementFile')">*</span>
          <i class="fa-regular fa-info-circle" appendTo="body" [pTooltip]="assignmentAgreementURLTooltip" tooltipPosition="top"></i>
        </label>
        <div class="flex items-center gap-1">
          <label class="cursor-pointer">
            <p-checkbox formControlName="AssignmentAgreementNeeded" [binary]="true"></p-checkbox>
            Not needed?
            <i class="fa-regular fa-info-circle" appendTo="body" [pTooltip]="assignmentAgreementNeededTooltip" tooltipPosition="top"></i>
          </label>
        </div>
      </div>
      <lfx-file-upload-input
        [form]="form"
        fileControlName="AssignmentAgreementFile"
        placeholder="Upload agreements"
        [showToggle]="false"
        placeholder="Upload agreement URL"
        [fileValidators]="[]"></lfx-file-upload-input>
    </div>

    <div class="project-detail lg:col-span-3">
      <label>Description <span class="text-red" *ngIf="fieldRequired('Description')">*</span></label>
      <textarea rows="2" pInputTextarea formControlName="Description"></textarea>
      <small *ngIf="hasError('Description')" class="text-xs text-red">{{ getError('Description') }}</small>
    </div>
    <div class="project-detail lg:col-span-3">
      <label
        >Mission Statement
        <span class="text-red" *ngIf="fieldRequired('MissionStatement')">*</span>
        <i
          class="fa-regular fa-info-circle ml-2"
          appendTo="body"
          [pTooltip]="missionStatementTooltip"
          tooltipPosition="top"
          [escape]="false"
          [autoHide]="false"></i
      ></label>
      <textarea rows="2" pInputTextarea formControlName="MissionStatement"></textarea>
      <small *ngIf="hasError('MissionStatement')" class="text-xs text-red">{{ getError('MissionStatement') }}</small>
    </div>
  </div>
</div>
<div class="flex justify-end gap-9 items-center mt-4">
  <a class="text-black text-13 cursor-pointer" (click)="onCancel()" *ngIf="isFirstStep">Cancel</a>
  <a class="text-black text-13 cursor-pointer" (click)="onPrevious()" *ngIf="!isFirstStep">Back</a>
  <button *ngIf="isFirstStep" pButton type="button" label="Next" class="p-button-primary" (click)="onNext()" [disabled]="isNextDisabled"></button>
  <button
    *ngIf="!isFirstStep"
    pButton
    type="button"
    class="p-button-primary flex items-center gap-2"
    (click)="onCreate()"
    [disabled]="form.invalid || formLoading">
    <span>Create Project</span>
    <i class="fa-light fa-circle-notch fa-spin" *ngIf="formLoading"></i>
  </button>
</div>

<ng-template #inputLoading>
  <p-skeleton height="34px"></p-skeleton>
</ng-template>
